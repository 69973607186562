import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
const $ = require("jquery")
$.Datatable = require("datatables.net");
// import "../transaction/imageview.css"


export default function PendingPayment() {
  const [data, setData] = useState([])
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value
    setLimit(key);
  }
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const All = () => {

    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl + `add-pay-by-mobile-User/manualpaymentlist-show-yhydhy?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        //setData(res.data)
        //$('table').dataTable();
        setData(res.data.admin);
        // console.log(res.data.admin);
        setNumberOfPages(res.data.totalPages)
        // imageViewer();
      })
  }

  useEffect(() => {
    All()
  }, [pageNumber, limit])

  const updateStatus = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };
  
    setIsButtonDisabled(true);
    axios.post(`${baseUrl}User-payment/manualpayment-accepted/approve-by-icic-bss/${Id}`, {}, { headers })
      .then((res) => {
        alert(res.data.message);
        All(); 
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        alert('Error updating status');
        setIsButtonDisabled(false);
      });
  };



  const rejectStatus = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };
  
    axios.post(`${baseUrl}User-reject-by-admin/manualpayment/reject-for-froedss/${Id}`, {}, { headers })
      .then((res) => {
        alert(res.data.message);
        All(); 
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        alert('Error updating status');
      });
  };



  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  }

  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="img-out"></div>
              <h4 className="card-title">Panding Payment</h4>

              <select className='form-control col-sm-2 m-1' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              <div className="table-responsive">
              <table className="table table-striped table-bordered">
              <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Amount</th>
                      <th> Phone</th>
                      <th> UTR Number</th>
                      <th> UPI ID</th>
                      <th> Account Detail</th>
                      <th> Payment Method</th>
                      <th> status </th>
                      <th> Approve or Reject </th>
                      <th> Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item._id}</td>
                        <td>{item.amount}</td>
                        <td>{item.Phone}</td>
                        <td>{item.urtID}</td>
                        <td>{item.upiId}</td>
                        <td>{item.accountNumber}<br></br>{item.ifscCode}</td> 
                        <td>{item.paymentMethod}</td>
                        <td>{item.status}</td>
                        {/* <td>{item.verified}</td> */}
                        <td>
                        <button className="btn btn-success mr-1" onClick={() => updateStatus(item._id, 'approve')} disabled={isButtonDisabled}>Approve</button>
                          <button className="btn btn-danger" onClick={() => rejectStatus(item._id, 'reject')}>Reject</button>
                        </td>
                        <td>{dateFormat(item.createdAt).split(',')[0]} </td>
                        {/* <td><Link to="/user/view" className="btn btn-primary">View</Link></td> */}
                      </tr>

                    ))}
                  </tbody>
                </table>
              </div>

              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
