import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import "../transaction/imageview.css";

export default function Transactionfriendhistory() {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const All = () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    axios.get(`${baseUrl}admin/transferslistwithfriend?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        setData(res.data.admin);
        setNumberOfPages(res.data.totalPages);
      })
      .catch(error => console.error("Error fetching transfers:", error));
  };
  

  useEffect(() => {
    All();
  }, [pageNumber, limit]);

  // Date formatting function
  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('default', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true 
    });
  };

  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Success Payment</h4>
              <select className='form-control col-sm-1 m-1' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>Sender (Phone)</th>
                      <th>Recipient (Phone)</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                      {data && data.map((item, index) => (
                        item.amount > 0 && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.txn_msg}</td>
                            <td>{item.User_id ? `${item.User_id.Name} (${item.User_id.Phone})` : "N/A"}</td>
                            <td>{item.amount}</td>
                            <td>{item.status}</td>
                            <td>{dateFormat(item.createdAt)}</td>
                          </tr>
                        )
                      ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
